import React from "react";
import { PageProps } from "gatsby";
import ContactModal from "@sections/ContactModal";
import PageWrapper from "@components/PageWrapper";
import SEO from "@components/SEO";

function ContactPage({ location }: PageProps) {
  return (
    <PageWrapper>
      <SEO title="Contact Us" pathname={location.pathname} />
      <ContactModal />
    </PageWrapper>
  );
}

export default ContactPage;

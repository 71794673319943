import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

function ContactFormSuccess() {
  return (
    <Wrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Header>Thanks for sharing!</Header>
      <Text>We'll be in touch with you as soon as we can.</Text>
    </Wrapper>
  );
}

export default ContactFormSuccess;

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 20vh;
`;

const Header = styled.h1`
  font-size: 2rem;
`;

const Text = styled.p`
  padding: 2.5%;
`;

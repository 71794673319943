import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { Circle } from "styled-spinkit";
import { useForm } from "react-hook-form";
import { motion, AnimatePresence } from "framer-motion";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import Stack from "@components/Stack";

import { media } from "@styles";
import { Button } from "@components/Button";
import CopyToClipboard from "@components/CopyToClipboard";
import { Instagram } from "@styled-icons/fa-brands/Instagram";
import { Twitter } from "@styled-icons/fa-brands/Twitter";

interface FormState {
  name: string;
  email: string;
  phone: string;
  company: string;
  position: string;
  message: string;
}

interface ContactFormIdleProps {
  send: any;
  isSubmitting: boolean;
}

const query = graphql`
  {
    strapiContactPage {
      First_header
      Second_header
    }
  }
`;

function ContactFormIdle({ send, isSubmitting }: ContactFormIdleProps) {
  const { register, handleSubmit, errors } = useForm<FormState>();
  const { strapiContactPage } = useStaticQuery(query);

  const { First_header, Second_header } = strapiContactPage;

  function onSubmit(values: FormState) {
    // trackCustomEvent({
    //   category: "Contact Form button",
    //   action: "Click",
    //   label: "User clicked Submit on Contact Form"
    // });
    send({ type: "SUBMIT", values });
  }

  return (
    <FormWrapper type="row" onSubmit={handleSubmit(onSubmit)} as="form">
      <Form type="column" gap={8} center stretchColumns>
        <Stack type="column" gap={5} center stretchColumns>
          <Header>{First_header}</Header>
          <Stack type="column" gap={4} stretchColumns>
            <Stack type="column" gap={1} stretchColumns>
              <Label>
                <Input
                  placeholder=" "
                  name="name"
                  type="text"
                  autoComplete="off"
                  ref={register({
                    required: "Don't forget your name!"
                  })}
                />
                <Span>Your name</Span>
              </Label>
              <AnimatePresence>
                {errors.name && errors.name.message && (
                  <ErrorMessage {...errorMessageAnimations}>
                    {errors.name.message}
                  </ErrorMessage>
                )}
              </AnimatePresence>
            </Stack>

            <Stack type="column" gap={1} stretchColumns>
              <Label>
                <Input
                  placeholder=" "
                  name="email"
                  type="email"
                  autoComplete="off"
                  ref={register({
                    required: "We can't reply without it...",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Please enter a valid email address."
                    }
                  })}
                />
                <Span>Your email</Span>
              </Label>
              <AnimatePresence>
                {errors.email && errors.email.message && (
                  <ErrorMessage {...errorMessageAnimations}>
                    {errors.email.message}
                  </ErrorMessage>
                )}
              </AnimatePresence>
            </Stack>

            <Stack type="column" gap={1} stretchColumns>
              <Label>
                <Input
                  placeholder=" "
                  name="company"
                  type="text"
                  autoComplete="off"
                  ref={register({
                    required: "Please tell us who you work for"
                  })}
                />
                <Span>Your company</Span>
              </Label>
              <AnimatePresence>
                {errors.company && errors.company.message && (
                  <ErrorMessage {...errorMessageAnimations}>
                    {errors.company.message}
                  </ErrorMessage>
                )}
              </AnimatePresence>
            </Stack>

            <Stack type="column" gap={1} stretchColumns>
              <Label>
                <Input
                  placeholder=" "
                  name="phone"
                  type="text"
                  autoComplete="off"
                  ref={register({
                    required: "Don't forget your phone"
                  })}
                />
                <Span>Your phone number</Span>
              </Label>
              <AnimatePresence>
                {errors.phone && errors.phone.message && (
                  <ErrorMessage {...errorMessageAnimations}>
                    {errors.phone.message}
                  </ErrorMessage>
                )}
              </AnimatePresence>
            </Stack>

            <Stack type="column" gap={1} stretchColumns>
              <Label>
                <Input
                  placeholder=" "
                  name="position"
                  type="text"
                  autoComplete="off"
                  ref={register({
                    required:
                      "Please tell us what your role is within your company"
                  })}
                />
                <Span>Your title of position</Span>
              </Label>
              <AnimatePresence>
                {errors.position && errors.position.message && (
                  <ErrorMessage {...errorMessageAnimations}>
                    {errors.position.message}
                  </ErrorMessage>
                )}
              </AnimatePresence>
            </Stack>
          </Stack>
        </Stack>

        <Stack type="column" gap={5} center stretchColumns>
          <Header>{Second_header}</Header>

          <Stack type="column" gap={1} stretchColumns>
            <Label>
              <Input
                placeholder=" "
                name="message"
                type="text"
                autoComplete="off"
                ref={register({
                  required: "Go on... tell us something!"
                })}
              />
              <Span>Let us know</Span>
            </Label>
            <AnimatePresence>
              {errors.message && errors.message.message && (
                <ErrorMessage {...errorMessageAnimations}>
                  {errors.message.message}
                </ErrorMessage>
              )}
            </AnimatePresence>
          </Stack>
        </Stack>
      </Form>

      <ButtonWrapper>
        <Button type="submit" icon="mail">
          {isSubmitting ? "Sending" : "Send"}
        </Button>
      </ButtonWrapper>

      <ButtonsGroup gap={1} type="column">
        <CopyToClipboard
          message="Prefer to send an email instead?"
          emailAddress="info@sympler.co"
        />
        <CopyToClipboard
          message="Press inquiries:"
          emailAddress="press@sympler.co"
        />
        <div
          style={{
            margin: "0 auto",
            width: "275px",
            padding: "2rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <p>On social media:</p>
          <Stack type="row" gap={1}>
            <IconContainer
              href="https://www.instagram.com/sympler"
              target="_blank"
              rel="noreferrer noopener"
            >
              <InstagramIcon />
            </IconContainer>

            <IconContainer
              href="https://www.twitter.com/symplerinc"
              target="_blank"
              rel="noreferrer noopener"
            >
              <TwitterIcon />
            </IconContainer>
          </Stack>
        </div>
      </ButtonsGroup>
    </FormWrapper>
  );
}

export default ContactFormIdle;

const errorMessageAnimations = {
  initial: { opacity: 0, y: -5 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 5 }
};

const FormWrapper = styled(Stack)`
  width: 100%;
  max-width: 950px;
  margin: 10vh auto 0 auto;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    "form copy-buttons"
    "form send-button";
  align-items: flex-start;
  position: relative;
  grid-gap: 1rem;

  @media (max-width: 950px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "form"
      "send-button"
      "copy-buttons";
    grid-gap: 8rem;
    grid-auto-flow: row;
  }
`;

const Form = styled(Stack)`
  width: 100%;
  padding: 0 10%;
  grid-area: form;
  /* width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 2rem;

  ${media.tablet`
    margin-top: 4rem;
  `} */
`;

const Header = styled.h1`
  font-size: clamp(2.9rem, 3.1vw, 3.5rem);
  font-weight: 300;
  line-height: 1;
  font-family: "Open Sans", sans-serif;
  color: hsl(0 0% 1%);
  margin: 0 auto;
  padding: 0.5rem 0;
  text-align: left;
  width: 100%;

  span {
    color: hotpink;
    font-style: italic;
  }
`;

const Label = styled.label`
  position: relative;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 1.8rem;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0;
  width: 99%;
  margin-left: auto;
`;

const Input = styled.input`
  font-family: "Open Sans", sans-serif;
  border: 0;
  border-radius: 0;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  font-size: 2rem;
  width: 100%;
  padding-bottom: 5px;
  padding-left: 3px;
  padding-top: 3px;
  border-bottom: 1px solid black;
  opacity: 0.8;
  display: inline-block;

  :focus {
    outline: none;
  }
  :focus + span {
    opacity: 1;
    transform: scale(0.75) translateY(-150%) translateX(-20px);
  }
  :not(:placeholder-shown) + span {
    opacity: 1;
    transform: scale(0.75) translateY(-150%) translateX(-20px);
  }
`;

const Span = styled.span`
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 15%;
  font-size: 2rem;
  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  color: hsl(0 0% 25%);
  display: inline-block;
`;

const ErrorMessage = styled(motion.p)`
  color: ${(p) => p.theme.colors.error};
  margin: 0 auto;
`;

const AdditionalInfo = styled.div`
  text-align: center;
  background: #fff;
  font-size: 2rem;
  min-height: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    display: inline-block;
    width: 100%;
  }

  span {
    padding: 0 1%;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  position: relative;
  grid-area: send-button;
  padding: 0 2rem;

  @media (max-width: 950px) {
    padding: 0;

    button {
      margin: 0 auto;
    }
  }
`;

const ButtonContent = styled(motion.div).attrs({
  variants: errorMessageAnimations,
  initial: "initial",
  animate: "animate",
  exit: "exit",
  transition: {
    duration: 0.5
  }
})`
  display: flex;
  place-content: center;
`;

const Spinner = styled(Circle).attrs({
  color: "white",
  size: 25
})`
  margin: 0 0.5rem 0 1rem;
  display: inline-block;
`;

const IconContainer = styled.a`
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InstagramIcon = styled(Instagram)`
  width: 100%;
  height: 100%;
  display: block;
  color: hsl(0 0% 10%);
`;

const TwitterIcon = styled(Twitter)`
  width: 100%;
  height: 100%;
  display: block;
  color: hsl(0 0% 10%);
`;

const ButtonsGroup = styled(Stack)`
  grid-area: copy-buttons;
`;

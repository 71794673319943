import React, { useEffect } from "react";
import styled from "styled-components";
import { useMachine } from "@xstate/react";

import { useContactModal } from "@hooks";
import { contactFormMachine } from "@machines/contact-form";
import {
  ContactFormIdle,
  ContactFormSuccess,
  ContactFormError
} from "@components/ContactForm";

function ContactForm() {
  const { toggleContact } = useContactModal();
  const [current, send] = useMachine(contactFormMachine);

  const showForm = current.matches("idle");
  const formSuccess = current.matches("success");
  const formError = current.matches("error");
  const isSubmitting = current.matches("loading");

  useEffect(() => {
    if (formSuccess || formError) {
      const closeAfterMs = setTimeout(toggleContact, 5000);

      return () => clearTimeout(closeAfterMs);
    }
  }, [formSuccess, formError, toggleContact]);

  return (
    <Wrapper>
      {(showForm || isSubmitting) && (
        <ContactFormIdle send={send} isSubmitting={isSubmitting} />
      )}
      {formSuccess && <ContactFormSuccess />}
      {formError && <ContactFormError />}
    </Wrapper>
  );
}

export default ContactForm;

const Wrapper = styled.div``;

import React, { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

import { CopyIcon, CopiedIcon } from "@icons";

function CopyToClipboard({
  emailAddress,
  message
}: {
  emailAddress: string;
  message: string;
}) {
  const [copied, setCopied] = useState<boolean>(false);
  const [isHovering, setHovering] = useState(false);

  function copyToClipboardOnClick(text: string) {
    const tempInput = document.createElement("input");
    document.body.appendChild(tempInput);
    tempInput.setAttribute("value", text);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    setCopied(true);
  }

  return (
    <AnimatePresence exitBeforeEnter>
      {copied ? (
        <CopyIconText
          key="copied"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <p>
            <strong style={{ display: "inline-block" }}>{emailAddress}</strong>{" "}
            copied to clipboard
          </p>
        </CopyIconText>
      ) : (
        <CopyIconText
          key="not-copied"
          onClick={() => copyToClipboardOnClick(emailAddress)}
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1
            // background: isHovering ? "rgb(230, 230, 230)" : "rgb(255, 255, 255)"
          }}
          // onHoverStart={() => setHovering(true)}
          // onHoverEnd={() => setHovering(false)}
          exit={{ opacity: 0 }}
        >
          <p>
            {message} <strong>{emailAddress}</strong>
          </p>
          {/* <div>{message}</div>
          <strong>{emailAddress}</strong> <CopyIcon /> */}
        </CopyIconText>
      )}
    </AnimatePresence>
  );
}

export default CopyToClipboard;

const CopyIconText = styled(motion.div)`
  margin: 0 auto;
  width: 275px;
  padding: 2rem;
  cursor: pointer;
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;

  /* div {
    display: block;
    white-space: nowrap;
    font-size: clamp(2rem, 5vw, 2.4rem);
  }

 

  span {
    font-size: clamp(1.55rem, 5vw, 1.9rem);
  } */

  p {
    width: 100%;
    white-space: nowrap;
  }

  strong {
    display: block;
  }

  svg {
    margin: 0 0.5rem;
  }
`;

export default function postFormData(values: {
  message: string;
  email: string;
  name: string;
  phone: string;
  company: string;
  position: string;
}) {
  if (process.env.NODE_ENV === "production") {
    const url = "https://hooks.zapier.com/hooks/catch/3869127/bc1uqzr/";
    const config = {
      method: "POST",
      body: JSON.stringify({
        body: values.message,
        email: values.email,
        name: values.name,
        phone: values.phone,
        company: values.company,
        position: values.position
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    };
    return fetch(url, config).then((response) => response.json());
  } else {
    return new Promise((resolve) => setTimeout(resolve, 4000));
  }
}

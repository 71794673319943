import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import ContactForm from "@components/ContactForm";

function ContactModal() {
  return (
    <Wrapper key="wrapper">
      <ContactForm />
    </Wrapper>
  );
}

export default ContactModal;

const Wrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 10rem 0;
`;

import { Machine } from "xstate";
import { postFormData } from "@api";

export const contactFormMachine = Machine({
  id: "contactForm",
  initial: "idle",
  states: {
    idle: {
      on: {
        SUBMIT: "loading"
      }
    },
    loading: {
      invoke: {
        id: "postFormData",
        src: (context, event) => postFormData(event.values),
        onDone: {
          target: "success"
        },
        onError: {
          target: "error"
        }
      }
    },
    success: {},
    error: {}
  }
});
